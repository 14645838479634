import CustomPopupMenu from "./CustomPopupMenu";
import "./style.css";

const GlobalUserListDropdown = ({
  className,
  handleSelect,
  userList = [],
  selected = [],
  selectedId = [],
}) => {
  const handleItemChange = (value, remove) => {
    handleSelect(value, remove);
  };

  return (
    <div
      className={`pypepro_global__custom__field__grouped__wraper deal-module-user-dropdown-wrapper ${className}`}
    >
      <CustomPopupMenu
        list={userList}
        handleClick={(value, remove) => handleItemChange(value, remove)}
        isLoading={false}
        value={selected}
        selectedId={selectedId}
        placeholder={"Filter by team member"}
      />
    </div>
  );
};
export default GlobalUserListDropdown;
