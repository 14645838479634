import React, { Fragment, useCallback, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { createPipelineStage } from "../../../../api/pipelineApi";
import BasicInput from "../../../custom/BasicInput";
import BasicSelect from "../../../custom/BasicSelect";
import { AddStageDrawerStyles } from "./AddStageDrawerStyles";
import { WIN_PROBABILITY } from "../../../../constants/CoreConstants";

const initialStages = [
  { title: "stage 1", background: "#376B3F", percentage: 9 },
  { title: "stage 2", background: "#376B3F", percentage: 9 },
  { title: "stage 3", background: "#376B3F", percentage: 9 },
];

const AddStageDrawer = ({ open, onClose, pipelineId, onSuccessCallback }) => {
  const [stages, setStages] = useState(initialStages);

  const handleUpdateStage = useCallback((field, value, index) => {
    setStages((prev) => {
      const updatedStages = [...prev];
      updatedStages[index][field] = value;
      return updatedStages;
    });
  }, []);

  const handleDeleteStage = useCallback(
    (index) => {
      if (stages.length > 1) {
        setStages((prev) => prev.filter((_, i) => i !== index));
      }
    },
    [stages]
  );

  const handleAddInputStage = useCallback(() => {
    setStages((prev) => [
      ...prev,
      { title: "", background: "#376B3F", percentage: 9 },
    ]);
  }, []);

  const handleSubmit = async () => {
    let isFormValid = true;
    const updatedStages = stages.map((stage) => {
      if (!stage.title) {
        isFormValid = false;
        return { ...stage, error: "Title is required" };
      }
      return { ...stage, error: null };
    });

    setStages(updatedStages);
    if (!isFormValid) return;

    const payload = { pipelineId: pipelineId || 48, stages };

    try {
      const response = await createPipelineStage(payload);
      if (response.success) {
        onSuccessCallback();
        window.showNotification("SUCCESS", response.message);
        onClose();
      } else {
        window.showNotification("error", response.message);
      }
    } catch (error) {
      console.error("Error submitting stage:", error);
      window.showNotification(
        "error",
        "Something went wrong! Please try again later."
      );
    }
  };

  return (
    <AddStageDrawerStyles anchor={"right"} open={open} onClose={onClose}>
      <div className="drawerWrapper">
        <div className="drawerHeader">
          <div
            style={{ fontSize: "20px", fontWeight: "600", height: "unset" }}
            variant="h5"
          >
            Add Stage
          </div>

          <div className="drawerHeaderActions">
            <IconButton size="small" onClick={onClose} color="secondary">
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div
          style={{
            height: "calc(100vh - 131px)",
          }}
          className="drawerBody"
        >
          <Grid container>
            <Grid item xs={12} md={5}>
              <div>
                Title <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                Win Probability <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <div style={{ textAlign: "center" }}>
                Color <span style={{ color: "red" }}>*</span>
              </div>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="stageList"
          >
            {stages.map((stage, index, arr) => (
              <Fragment key={index}>
                <Grid item xs={12} md={5}>
                  <div style={{ position: "relative" }}>
                    <BasicInput
                      value={stage.title}
                      name="title"
                      placeholder="Enter Title"
                      className="stageTitle"
                      fullWidth
                      onChange={(e) =>
                        handleUpdateStage("title", e.target.value, index)
                      }
                    />

                    {stage?.error && (
                      <div className="titleError">Title is required</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <BasicSelect
                    name="percentage"
                    value={stage.percentage}
                    options={WIN_PROBABILITY}
                    onChange={(event) =>
                      handleUpdateStage("percentage", event.target.value, index)
                    }
                    mapping={{
                      label: "label",
                      value: "value",
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <BasicInput
                    defaultValue={stage.background}
                    name="background"
                    type="color"
                    fullWidth
                    className="colorPicker"
                    onChange={(e) =>
                      handleUpdateStage("background", e.target.value, index)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      name="delete"
                      size="small"
                      onClick={() => handleDeleteStage(index)}
                      disabled={arr.length === 1}
                      className="inputDelete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Grid container style={{marginTop:'8px'}}>
            <Grid item xs={12}>
              <div style={{}} className="addDInputStage">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleAddInputStage}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={"modalFooter"}>
          <Button
            variant="contained"
            className="submitButton"
            color="primary"
            // size="small"
            disableElevation
            startIcon={<AddIcon />}
            onClick={handleSubmit}
          >
            Add Stage
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            // size="small"
            disableElevation
            className="cancelButton"
          >
            Cancel
          </Button>
        </div>
      </div>
    </AddStageDrawerStyles>
  );
};

export default AddStageDrawer;
