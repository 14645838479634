import { makeStyles, Tabs } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  tabsRoot: {
    '& .MuiTabs-indicator, & .PrivateTabIndicator-root': {  
        width: '160px !important',
        backgroundColor: 'transparent',
        display:"none !important",
      },
    position: 'relative',
    '& .Mui-selected': {
      color: '#1976d2',
      fontWeight:600
    },
  },
  customIndicator: {
    position: 'absolute',
    bottom: 0,
    left: '0%', 
    width: '160px',
    height: '2px',
    backgroundColor: '#1976d2',
    transition: 'left 0.3s ease',
  },
}));

const CustomTabs = ({ value, onChange, children }) => {
  const classes = useStyles();
  const tabWidth = 160;

  const indicatorStyle = {
    left: `${value * tabWidth}px`,
  };

  return (
    <div style={{ position: 'relative' }}>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        className={classes.tabsRoot}
        component={"div"}
      >
        {children}
      </Tabs>
      <div className={classes.customIndicator} style={indicatorStyle} />
    </div>
  );
};

export default CustomTabs;
