import { IconButton, makeStyles } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "bootstrap-daterangepicker/daterangepicker.css";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
const useStyles = makeStyles((theme) => ({
  customButton: {
    borderRadius: 4,
    border: "1px solid #e5e5e5 !important",
    padding: "7px 6px",
    margin: "0px",
    lineHeight: " 1px",
    "&:hover": {
      borderColor: "none",
      backgroundColor: "white",
    },
    "&:focus": {
      borderColor: "none",
      backgroundColor: "white",
    },
  },
  buttonText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    color: "rgba(19, 49, 89, 0.65)",
  },
}));
const CustomDateRangePicker = (props) => {
  const classes = useStyles();
  const onApply = (event, picker) => {
    props.onApply(picker);
  };

  const onCancel = (event, picker) => {
    props.onCancel();
  };

  return (
    <DateRangePicker
      initialSettings={
        typeof props.dateRange != "undefined" &&
        props.dateRange.startDate != null &&
        props.dateRange.endDate != null
          ? props.dateRange
          : null
      }
      onApply={onApply}
      onCancel={onCancel}
    >
      <IconButton className={classes.customButton}>
        <span className={classes.buttonText}>
          <DateRangeIcon /> <ExpandMoreIcon />
        </span>
      </IconButton>
    </DateRangePicker>
  );
};
export default CustomDateRangePicker;
