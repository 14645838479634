import { InputAdornment, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GridOnIcon from "@material-ui/icons/GridOn";
import ListIcon from "@material-ui/icons/List";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getAllPipeline,
  storeSelectedDealInfo,
  updateDealFilerOptions,
  updateDealStageReloadFlag,
} from "../../../actions/DealAction";
import {
  storeDateRange,
  storeDealStatus,
  storePipelineId,
  storePipelineList,
  storeSearchText,
  storeSelectAUser,
  storeTeamUserList,
} from "../../../actions/DealReducerAction";
import { getTeamUserListApi } from "../../../api/dealApi";
import { IconList } from "../../../constants/IconList";
import Utils, { getAccountData } from "../../../helpers/Utils";
import { useIsMounted } from "../../../hooks/IsMounted";
import CustomDateRangePicker from "../../Common/CustomDateRangePicker";
import CustomSelect from "../../Common/Form-component/CustomSelect";
import BasicInputForSearch from "../../custom/BasicInputForSearch";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import GlobalUserListDropdown from "../../UserListDropdown/Index";
import "../css/deal-pipeline-v2.css";
import Styles from "./grid-view-v2/eachi-item/style.module.css";
import { searchIcon, settingsIcon } from "../../custom-menu/Icons";
import { DEAL_GRID_VIEW, DEAL_LIST_VIEW, DEAL_REPORT_VIEW } from "../../SendComponents/SmsSendModule/components/personalized/const";
import { AGENCY_BUSINESS_TYPE } from "../../../constants/CoreConstants";
import PipelineFilterWithPrivacy from "./pipelineFilterWithPrivacy/Index";

const StyledTooltip = withStyles({
  tooltip: {
    color: "#fff",
  },
  arrow: {
    color: "#15008A",
  },
  tooltipPlacementTop: {
    background: "#15008A",
  },
  tooltipPlacementBottom: {
    background: "#15008A",
  },
  tooltipPlacementRight: {
    background: "#15008A",
  },
})(BootstrapTooltip);

const useStyles = makeStyles((theme) => ({
  topbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    padding: "5px 7px",
    [`@media (max-width: 1710px)`]: {
      flexDirection: "column",
    }
  },
  topbarLeftSide: {
    display: "flex", 
    alignItems: "center", 
    gap: 10,
    justifyContent: "start",

    "& .pipeline-select .MuiSelect-root": {
      backgroundColor: 'white !important'
    },

    "& .userWrapper":{
      "& .custom_menu_option_container .label_class_wrapper": {
        height: "40px !important",
        borderColor: "#e5e5e5 !important",
      }
    }
  },
  rightSideContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 10,
    [`@media (max-width: 1710px)`]: {
      width: "100%",
      justifyContent: "flex-start"
    }
  },
  actionContainer: {
    display: "flex", 
    alignItems: "center",
    gap: 2
  },
  addDealButton: {
    minWidth: "140px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#445af3",
    borderRadius: "4px",
    padding: "4px 15px",
    border: "none",
    gap: 5,
    cursor: "pointer",
    minHeight: "37px",
    "&:hover": {
      backgroundColor: "#001ff6",
    },
    "&:focus": {
      backgroundColor: "#445af3",
    },
  },
  dealViewButton: {
    backgroundColor: "#e9f0f8",
    padding: "10px 15px",
    border: "none",
    gap: 10,
    cursor: "pointer",
    minHeight: "42px",
    "&:hover": {
      backgroundColor: "#163159",
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
    "&:focus": {
      backgroundColor: "#001ff6",
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
  },
  iconContainer: {
    '&:hover .settings-icon path': {
      fill: 'white !important',
    },
    '&:focus .settings-icon path': {
      fill: 'white !important',
    },
  },
  buttonLabel: {
    color: "white",
    fontWeight: 500,
    textAlign: "center"
  },
  
}));

const statusItem = [
  { value: 1, title: "Active" },
  { value: 2, title: "Won", icon: IconList.likeIcon },
  { value: 3, title: "Lost", icon: IconList.dislikeIcon },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let filterModified = false;

const DealTopbar = (props) => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const [pipeLineList, setPipeLineList] = useState([]);
  const [selectedPipeline, setselectedPipeline] = useState(props.pipelineId);
  const [seletedStatus, setSeletedStatus] = useState(props.status);
  const [selectedDateRange, setSelectedDateRange] = useState(props.dateTime);
  const [, setSearchText] = useState(props.search);
  let queryParams = useQuery();
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const starterPack = Utils.getAccountData("starterPack");

  /* filter by user */
  const [users, setUsers] = useState(props.users);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allPipeline, setAllPipeline] = useState([]);
  const [selectedPipelineOwner, setSelectedPipelineOwner] = useState(null);

  useEffect(() => {
    getTeamUserListApi()
      .then((res) => {
        let response = res.data;
        if (response.status) {
          let subUsersArray = [];
          response.data.forEach((subUser) => {
            subUsersArray.push({
              value: subUser.id,
              label: subUser.full_name,
            });
          });
          setUsers(subUsersArray);
          props.storeTeamUserList(subUsersArray);
        }
      })
      .catch((error) => {
        props.storeTeamUserList([]);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.getAllPipeline({}, (response) => {
      setPipeLineList(response.data);
      setAllPipeline(response.data);
      props.storePipelineList(response.data);
      if (props.pipelineIdInDealList === "") {
        //if you want to refresh remove this clouser
        let pipeLength = response.data.length;
        let selectedPipeId = null;
        if (pipeLength > 0) {
          if (
            queryParams.get("id") !== undefined &&
            queryParams.get("id") != null &&
            queryParams.get("id") !== ""
          ) {
            for (let i = 0; i < pipeLength; i++) {
              if (queryParams.get("id") == response.data[i].id) {
                selectedPipeId = queryParams.get("id");
                break;
              }
            }
            if (selectedPipeId != null) {

              setselectedPipeline(selectedPipeId);
              props.updateDealFilerOptions({
                pipelineId: selectedPipeId,
              });
            } else {

              setselectedPipeline(response.data[0].id);
              props.updateDealFilerOptions({
                pipelineId: response.data[0].id,
              });
            }
          } else {
            /* TODO: check in cookie */
            if (checkCookie()) {
              const cookie_data = getCookieData();
              if (
                cookie_data !== undefined &&
                cookie_data != null &&
                cookie_data !== ""
              ) {
                let selected_id = null;
                for (let i = 0; i < pipeLength; i++) {
                  if (response.data[i].id == cookie_data) {
                    selected_id = cookie_data;
                    break;
                  }
                }
                if (selected_id != null) {

                  setselectedPipeline(parseInt(cookie_data));
                  props.updateDealFilerOptions({
                    pipelineId: parseInt(cookie_data),
                  });
                } else {

                  setselectedPipeline(response.data[0].id);
                  props.updateDealFilerOptions({
                    pipelineId: response.data[0].id,
                  });
                }
              } else {

                setselectedPipeline(response.data[0].id);
                props.updateDealFilerOptions({
                  pipelineId: response.data[0].id,
                });
              }
            } else {
              if (response.data[0].user_id === Utils.getAccountData("userId")) {
                setCookieData(response.data[0].id);
              }

              setselectedPipeline(response.data[0].id);
              props.updateDealFilerOptions({
                pipelineId: response.data[0].id,
              });
            }
          }
        }
      } else {
        let pipeLength = response?.data.length;
        /* check pipeline id in cookie */
        if (checkCookie() && pipeLength !== undefined && pipeLength != null) {
          const cookie_data = getCookieData();
          if (
            cookie_data !== undefined &&
            cookie_data != null &&
            cookie_data !== ""
          ) {
            let selected_id = null;
            for (let i = 0; i < pipeLength; i++) {
              if (response.data[i].id == cookie_data) {
                selected_id = cookie_data;
                break;
              }
            }
            if (selected_id != null) {

              setselectedPipeline(parseInt(cookie_data));
              props.updateDealFilerOptions({
                pipelineId: parseInt(cookie_data),
              });
            }
          }
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterModified) {
      return;
    }
    if (allPipeline.length > 0 && users.length > 0) {
      // if (getAccountData("userIsOwner")){
      let newData = [], newIds = [];
      for (let i = 0; i < users.length; i++) {
        if (users[i].value === getAccountData("userId") ||
          (users[i].value === getAccountData("parentId") && getAccountData("userId") !== getAccountData("parentId"))) {
          newData.push(users[i]);
          newIds.push(users[i].value);
        }
        filterModified = true;
      }


      if (!newData[0]) {
        setSelectedUsers([{ value: "__all__", label: "All users" }]);
        setSelectedIds(["__all__"]);
      } else {
        setSelectedUsers(newData);
        setSelectedIds(newIds);
      }
      // }else {

      //   setSelectedUsers([{ value: "__all__", label: "All users" }]);
      //   setSelectedIds(["__all__"]);
      // }
    }
  }, [allPipeline, users]);

  useEffect(() => {

    setselectedPipeline(props.pipelineId);
    // if (props.pipelineId != null && props.pipelineId !== "" && Utils.getAccountData("userId") === selectedPipelineOwner) {
    setCookieData(props.pipelineId);
    // }
  }, [props.pipelineId, selectedPipelineOwner]);

  useEffect(() => {
    if (isMounted) {
      try {
        if (selectedIds[0] === "__all__") {
          setPipeLineList(allPipeline);
          props.storePipelineList(allPipeline);

          if (selectedPipeline) {

            return;
          }
          if (allPipeline.length > 0 && selectedPipeline != null) {

            setselectedPipeline(allPipeline[0].id);
            props.updateDealFilerOptions({
              pipelineId: allPipeline[0].id,
            });
            setSelectedPipelineOwner(allPipeline[0].user_id);
          }
          else {
            setselectedPipeline("");
            props.updateDealFilerOptions({
              pipelineId: "",
            });
          }
          return;
        }
        if (selectedIds.length === 0) {
          setPipeLineList(allPipeline);
          props.storePipelineList(allPipeline);
          if (allPipeline.length > 0) {

            setselectedPipeline(allPipeline[0].id);
            props.updateDealFilerOptions({
              pipelineId: allPipeline[0].id,
            });
            setSelectedPipelineOwner(allPipeline[0].user_id);
          }
          else {
            setselectedPipeline("");
            props.updateDealFilerOptions({
              pipelineId: "",
            });
          }
          return;
        }
        let newData = [];
        let matchWithPrevious = false;
        allPipeline.forEach((item) => {
          if (selectedIds.includes(item.user_id)) {
            newData.push(item);
            if (item.id == props.pipelineId) {
              matchWithPrevious = true;
            }
          }
        });
        if (!matchWithPrevious) {
          if (newData.length > 0) {

            setselectedPipeline(newData[0].id);
            props.updateDealFilerOptions({
              pipelineId: newData[0].id,
            });
            setSelectedPipelineOwner(newData[0].user_id);
          }
        }
        setPipeLineList(newData);
        props.storePipelineList(newData);
        if (newData.length > 0) {
          // setselectedPipeline(newData[0].id);
          // props.updateDealFilerOptions({
          //   pipelineId: newData[0].id,
          // });
        }
        else {
          setselectedPipeline("");
          props.updateDealFilerOptions({
            pipelineId: "",
          });
        }
      } catch (error) { }
    }
  }, [selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCookieData = (id) => {
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=` + id + ";" + expires + ";path=/";
  };
  const getCookieData = () => {
    let name = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const checkCookie = (cname = `selectd_pipeline_id_in_deal:${Utils.getAccountData("userId")}`) => {
    const cookieInfo = getCookieData(cname);
    return !!cookieInfo;
  };

  const handlePipelineChange = (value, owner = null) => {
    setselectedPipeline(value);
    // props.storePipelineId(value)
    props.updateDealFilerOptions({
      pipelineId: value,
    });


    if (owner) {
      if (owner.user_id === Utils.getAccountData("userId")) {
        setCookieData(value);
      }
    }
  };
  const handleStatusChange = (value) => {
    setSeletedStatus(value);
    props.updateDealFilerOptions({
      status: value,
    });
  };
  const handleDateRangeChange = (value) => {
    // formate value
    let start_date, end_date;
    start_date = moment(value.startDate).format("MM/DD/YYYY");
    end_date = moment(value.endDate).format("MM/DD/YYYY");
    setSelectedDateRange({ startDate: start_date, endDate: end_date });
    props.updateDealFilerOptions({
      dateTime: { startDate: start_date, endDate: end_date },
    });
  };
  const handleSearch = (value) => {
    setSearchText(value);
    props.updateDealFilerOptions({
      search: value,
    });
  };
  const handleOnCancel = (value) => {
    setSelectedDateRange({ startDate: null, endDate: null });
    props.updateDealFilerOptions({
      dateTime: { startDate: null, endDate: null },
    });
  };

  const renderPipelineList = () => {
    if (Utils.getAccountData("userIsAgent")) {
      const list = [];
      pipeLineList.forEach((item) => {
        if (
          item.user.role_user.role_id === 3 &&
          item.user_id === Utils.getAccountData("userId")
        ) {
          list.push(item);
        } else if (item.user.role_user.role_id !== 3) {
          list.push(item);
        }
      });
      return list;
    }
    return pipeLineList;
  };


  const handleSelectUser = (item, remove) => {
    if (item.value === "__clear_all__") {
      setSelectedUsers([]);
      setSelectedIds([]);
      return;
    }
    if (item.value === "__all__") {
      if (remove) {
        setSelectedUsers([]);
        setSelectedIds([]);
      } else {
        setSelectedUsers([item]);
        setSelectedIds(["__all__"]);
      }
    } else {
      if (selectedIds.length > 0 && selectedIds[0] === "__all__") {
        setSelectedUsers([item]);
        setSelectedIds([item.value]);
      } else {
        let old_data_ids = [...selectedIds];
        let old_data_full = [...selectedUsers];
        if (remove) {
          let filter_old_data_ids = old_data_ids.filter(
            (list) => item.value !== list
          );
          setSelectedIds(filter_old_data_ids);

          let filter_old_data_full = old_data_full.filter(
            (list) => item.value !== list.value
          );
          setSelectedUsers(filter_old_data_full);
        } else {
          old_data_ids.push(item.value);
          old_data_full.push(item);
          setSelectedUsers(old_data_full);
          setSelectedIds(old_data_ids);
        }
      }
    }
  };

  return (
    <div className={classes.topbarContainer}>
      <div className={classes.topbarLeftSide}>
        <div className={classes.actionContainer}>
          <BootstrapTooltip title="Grib View" arrow placement="top">
            <button
              className={`${classes.dealViewButton} ${
                props.dataViewType === DEAL_GRID_VIEW
                  ? "active active-icon accent--bg--text--color"
                  : ""
              }`}
              style={{
                borderLeft: "5px",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
              onClick={(e) => props.handleChangeDataView(true, 1, e)}
            >
              <GridOnIcon />
            </button>
          </BootstrapTooltip>
          <BootstrapTooltip title="List View" arrow placement="top">
            <button
              className={`${classes.dealViewButton} ${
                props.dataViewType === DEAL_LIST_VIEW
                  ? "active active-icon accent--bg--text--color"
                  : ""
              }`}
              onClick={(e) => props.handleChangeDataView(true, 2, e)}
            >
              {" "}
              <ListIcon />{" "}
            </button>
          </BootstrapTooltip>
          <BootstrapTooltip title="Report" arrow placement="top">
            <button
              className={`${classes.dealViewButton} ${
                props.dataViewType === DEAL_REPORT_VIEW
                  ? "active active-icon accent--bg--text--color"
                  : ""
              }`}
              onClick={(e) => props.handleChangeDataView(false, 3, e)}
            >
              <TrendingUpIcon />
            </button>
          </BootstrapTooltip>
          <BootstrapTooltip title="Pipeline Settings" arrow placement="top">
            <button
              className={`${classes.dealViewButton} ${classes.iconContainer}`}
              style={{
                borderRight: "5px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <Link to="pipelines">
                <div>
                  {settingsIcon({ width: 21, height: 21, fill: "#000" })}
                </div>
              </Link>
            </button>
          </BootstrapTooltip>
        </div>

        {props.dataViewType === DEAL_GRID_VIEW ? (
          <div style={{display:"flex",alignItems:"center", marginInline: 8 }}>
            <div>
              <StyledTooltip
                arrow
                placement="top"
                title={
                  <span className={Styles.dealValue_Tooltip}>
                    {Utils.calculateCashPlain(props.stats.totalDealValue)}
                  </span>
                }
              >
                <span>{Utils.calculateCash(props.stats.totalDealValue)}</span>
              </StyledTooltip>
            </div>
            <span style={{ padding: "0px 10px", marginBottom: 6 }}>
              <FiberManualRecordIcon style={{ fontSize: 4, color: "#d5d5d5" }} />
            </span>
            <div>
                <span>
                  {props.stats.totalDeals} DEALS{" "}
                </span>
            </div>
          </div>
        ) : null}
        {props.showOptions && (
          <>
          {Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE ? (
              <PipelineFilterWithPrivacy
                pipeLineList={pipeLineList}
                setPipeLineList={setPipeLineList}
                selectedPipeline={selectedPipeline}
                onChangePipeline={handlePipelineChange}
              />
            ) : (
              <div className="action-btn-dropdown dm_tbps_alt">
                <CustomSelect
                  value={selectedPipeline}
                  handleChange={(event) => {
                    let value = pipeLineList.find(
                      (pipeLine) => pipeLine.id === event.target.value
                    );
                    handlePipelineChange(event.target.value, value);
                  }}
                  placeholder={
                    <MenuItem value="" disabled>
                      {" "}
                      Select a pipeline{" "}
                    </MenuItem>
                  }
                  // item={pipeLineList}
                  item={renderPipelineList()}
                  value_field={"id"}
                  titel_field={"title"}
                  customClass={"pipeline-select pipeline-select-alt-v2"}
                  menuItemClass={
                    "dropdownhelper-menuitem-class d-flex dark-menuitem-color"
                  }
                  modifyTitle={(text, item) => {
                    return (
                      <BootstrapTooltip
                        arrow
                        placement="top"
                        title={item.title + " - " + item.user.full_name}
                      >
                        <span
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            maxWidth: "250px",
                            overflow: "hidden",
                          }}
                        >
                          {item.title}{" "}
                          <small>
                            <code>{item.user.full_name}</code>
                          </small>
                        </span>
                      </BootstrapTooltip>
                    );
                  }}
                />
              </div>
            )}
            {props.dataViewType === DEAL_GRID_VIEW && (
              <div className="action-btn-dropdown dm_tbps_alt">
                <GlobalUserListDropdown
                  userList={users}
                  handleSelect={(value, remove) =>
                    handleSelectUser(value, remove)
                  }
                  selected={selectedUsers}
                  selectedId={selectedIds}
                  className={'userWrapper'}
                />
              </div>
            )}
            <div
              className="action-btn-dropdown statusDropdown"
              style={{ width: "unset !important" }}
            >
              <CustomSelect
                value={seletedStatus}
                handleChange={(event) => handleStatusChange(event.target.value)}
                item={statusItem}
                value_field={"value"}
                titel_field={"title"}
                menuItemClass={"activeItemLi deal-list-status"}
                customClass={"pipeline-select pipeline-select-alt-v2"}
              />
            </div>
            {props.dataViewType === DEAL_GRID_VIEW && (
              <div>
                <CustomDateRangePicker
                  dateRange={selectedDateRange}
                  onApply={handleDateRangeChange}
                  onCancel={handleOnCancel}
                  // value={selectedDateRange.startDate+':'+selectedDateRange.startDate}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.rightSideContainer}>
        {props.showOptions && (
          <div style={{maxWidth: 235}}>
            <BasicInputForSearch
              placeholder="Search deals"
              endAdornment={
                <InputAdornment
                  position="start"
                  style={{ paddingLeft: "18px" }}
                >
                  <span>{searchIcon({ width: 20, height: 20 })}</span>
                </InputAdornment>
              }
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>
        )}

        {!isTempUser && !starterPack && (
          <div>
            <button
              className={classes.addDealButton}
              onClick={() => {
                props.handleAddDealModal(true, false, selectedPipeline);
              }}
            >
              <AddIcon style={{ color: "white" }} />
              <span className={classes.buttonLabel}>New Deal</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pipelineIdInDealList: state.dealReducer.pipelineIdInDealList,
  searchTextInDealList: state.dealReducer.searchTextInDealList,
  calerdarSortingInDealList: state.dealReducer.calerdarSortingInDealList,
  statusInDealList: state.dealReducer.statusInDealList,

  /* for new deal reducers */
  pipelineId: state.dealReducerV2.pipelineId,
  search: state.dealReducerV2.search,
  dateTime: state.dealReducerV2.dateTime,
  status: state.dealReducerV2.status,

  users: state.dealReducerV2.users,
  selectedUser: state.dealReducerV2.selectedUser,
});
const mapDispatchToProps = (dispatch) => ({
  getAllPipeline: (params, callback) =>
    dispatch(getAllPipeline(params, callback)),
  storePipelineId: (params, callback) =>
    dispatch(storePipelineId(params, callback)),
  storeDealStatus: (params, callback) =>
    dispatch(storeDealStatus(params, callback)),
  storeDateRange: (params, callback) =>
    dispatch(storeDateRange(params, callback)),
  storeSearchText: (params, callback) =>
    dispatch(storeSearchText(params, callback)),

  /* deal module new V2 */
  updateDealFilerOptions: (params) => dispatch(updateDealFilerOptions(params)),
  storeSelectedDealInfo: (params) => dispatch(storeSelectedDealInfo(params)),
  updateDealStageReloadFlag: (params) =>
    dispatch(updateDealStageReloadFlag(params)),
  storePipelineList: (params) => dispatch(storePipelineList(params)),
  storeTeamUserList: (params) => dispatch(storeTeamUserList(params)),
  storeSelectAUser: (params) => dispatch(storeSelectAUser(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DealTopbar);
