import { makeStyles, Paper, styled } from "@material-ui/core";

export const pipelineFilterWithPrivacyStyles = makeStyles((theme) => ({
  root: {
    "& .pipelineButton": {
      fontWeight: 600,
      color: "#133159a6",
      border: "1px solid #e5e5e5 !important",
      padding: "7px 15px",
      borderRadius: "4px 0 0 4px !important",

      "& svg": {
        color: "#133159a6",
      },

      "&:hover, &:focus": {
        backgroundColor: "transparent !important",
      },

      "&.edit": {
        borderLeft: "none !important",
        borderRadius: "0 4px 4px 0 !important",
        minWidth: 0,
        padding: "8px 10px",

        "& svg": {
          fontSize: 16,
        },
      },

      "& .filterTitle": {
        width: 180,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: "left",
      }
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus-visible": {
      outline: "none",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(0.5),
    width: 500,
    // height: "98vh",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalHeader: {
    Color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
}));

export const PipelineFilterPopoverWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: "0 !important",
  width: "100%",

  "& .tabWrapper": {
    // marginBottom: 8,
  },
  "& .tabItem": {
    textTransform: "capitalize",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
  },

  "& .pipelineList": {
    "& .MuiListItem-container .secondaryAction .editAction": {
      display: "none",
    },

    "& .MuiListItem-container:hover": {
      "& .secondaryAction .editAction": {
        display: "inline-flex",
      },
      "& .secondaryAction .checkAction": {
        display: "none",
      },
    },

    "& .noData": {
      textAlign: "center",
      paddingBlock: 16,
    },
  },

  "& .footer": {
    "& .addPipelineButton": {
      "&:hover, &:focus": {
        backgroundColor: "transparent !important",
      },
    },
  },

  "& .pipelineScroll": {
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: 'transparent !important',
    scrollbarWidth: "thin",
    scrollbarColor: "#888 transparent", // Change color as needed
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Change color as needed
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Change color as needed
    },
  },
}));

export const addPipelineModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus-visible": {
      outline: "none",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(0.5),
    width: 500,
    // height: "98vh",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalHeader: {
    Color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 16,
    "& .MuiSelect-select.Mui-disabled": {
      cursor: "not-allowed !important",
      backgroundColor: "#e5e5e5 !important",
    },
  },
  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px",
    borderTop: "1px solid rgba(148, 157, 178, 0.24)",
    gap: "16px",
    "& .submitButton": {
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },
  },

  errorText: {
    color: "var(--red)",
    fontSize: "12px",
    margin: "4px 0px 0px 6px",
  },
}));

export const pipelineItemStyles = makeStyles((theme) => ({
  root: {
    "& .secondaryAction .editAction": {
      display: "none",
    },
    "&:hover .secondaryAction .editAction": {
      display: "inline-flex",
    },

    "& .primaryText span": {
      maxWidth: 246,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }
  },
}));
