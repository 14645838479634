import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Utils from "../../../../helpers/Utils";
import { PRIVACY_LEVEL } from "../../../../constants/CoreConstants";
import { addPipelineWithPrivacy } from "../../../../api/pipelineApi";
import BasicSelect from "../../../custom/BasicSelect";
import { BasicInput } from "../../../products/productsStyle";
import { addPipelineModalStyles } from "./pipelineFilterWithPrivacyStyle";

const PRIVACY_OPTION = [
  {
    value: PRIVACY_LEVEL.GLOBAL,
    label: "Global Pipeline",
  },
  {
    value: PRIVACY_LEVEL.PRIVATE,
    label: "My Pipeline",
  },
];

const isUserAgent = Utils.getAccountData("userIsAgent");

const AddPipelineModal = ({ open, onClose, activeTab, setPipeLineList, onChangePipeline }) => {
  const [title, setTitle] = useState("");
  const [privacyType, setPrivacyType] = useState(
    isUserAgent ? PRIVACY_LEVEL.PRIVATE :
    activeTab === PRIVACY_LEVEL.GLOBAL
      ? PRIVACY_OPTION[0].value
      : PRIVACY_OPTION[1].value
  );

  const classes = addPipelineModalStyles();

  const [errorMessage, setErrorMessage] = useState({
    title: "",
  });



  const validateForm = () => {
    const errors = {};

    if (!title.trim()) {
      errors.title = "Title is required.";
    }

    setErrorMessage(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const submitPayload = {
      title,
      privacyLevel: privacyType,
    };

    try {
      const response = await addPipelineWithPrivacy(submitPayload);

      if (response.success) {
        setPipeLineList((prevState) => [...prevState, response.data]);

        window.showNotification("SUCCESS", response.message);

        onChangePipeline(response.data.id, response.data);
        onClose();
      } else if (!response.success && response.data === null) {
        setErrorMessage((prevState) => ({
          ...prevState,
          title: response.message,
        }));
      } else {
        window.showNotification("error", response.message);
      }
    } catch (error) {
      console.error("Error submitting tag:", error);
      window.showNotification(
        "error",
        "Something went wrong! Please try again later."
      );
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <div>
              <h5 style={{ margin: 0, fontSize: 20 }}>New Pipeline</h5>
            </div>
            <IconButton size={"small"} onClick={onClose}>
              <CloseIcon style={{ color: "red" }} color="closeBtnColor" />
            </IconButton>
          </div>
          <Divider />

          <div className={classes.modalBody}>
            <div>
              <div style={{ marginBottom: 8 }}>
                Title <span style={{ color: "red" }}>*</span>
              </div>
              <BasicInput
                fullWidth
                placeholder="Enter title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />

              {errorMessage.title && (
                <div className={classes.errorText}>{errorMessage.title}</div>
              )}
            </div>

            <div>
              <div style={{ marginBottom: 8 }}>
                Type <span style={{ color: "red" }}>*</span>
              </div>

              <BasicSelect
                fullWidth
                name="pipelineType"
                defaultText="Select an type"
                value={privacyType}
                options={PRIVACY_OPTION}
                onChange={(event) => setPrivacyType(event.target.value)}
                disabled={isUserAgent}
                mapping={{
                  label: "label",
                  value: "value",
                }}
              />
            </div>
          </div>

          <div className={classes.modalFooter}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              size="small"
              disableElevation
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="submitButton"
              color="primary"
              size="small"
              disableElevation
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddPipelineModal;
