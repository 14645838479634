import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { deleteAndMoveToStage, getUserStageByPipeline, sortUserStage, userStageShowInChart } from '../../../../actions/PipelineAction';
import { reloadPipeline, reloadStageList } from '../../../../actions/PipelineReducerAction';
import Utils from '../../../../helpers/Utils';
import EachPipeline from './EachPipeline';
import PipelineSkeleton from './PipelineSkeleton';
import AddStageConfirmation from '../Modals/AddStageConfirmation';
import AddStageDrawer from '../drawer/AddStageDrawer';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '',
  display: 'flex',
  width: "100%",
  paddingTop: 10,
  borderRradius: 10
  // padding: grid,
  // overflow: 'auto',
});

  const PipelineBodyApp = (props) => {
  const [stages, setStages] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAddStageDrawer, setIsAddStageDrawer] = useState(false)
  const pipelineContainerRef = useRef(null)


    useEffect(() => {
      // eslint-disable-next-line eqeqeq
      if(props.seletedPipelineId != ''){
          setLoading(true)
          props.getUserStageByPipeline({pipeline_id : props.seletedPipelineId}, (res) => {
              setStages(res.stages)
              setLoading(false)
              if(props.addStage){
                  props.reloadPipeline({addStageFlag: false})
                  pipelineContainerRef.current.scrollIntoView({ behavior: 'smooth' })
              }
          })
      }else{
          setLoading(false)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.seletedPipelineId])

  useEffect(() => {
      if(props.needToReloadStageList){
          props.reloadStageList(false)
          setLoading(true)
          props.getUserStageByPipeline({pipeline_id : props.seletedPipelineId}, (res) => {
              setStages(res.stages)
              setLoading(false)
              if(props.addStage){
                  props.reloadPipeline({addStageFlag: false})
                  pipelineContainerRef.current.scrollIntoView({ behavior: 'smooth' })
              }
          })
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.needToReloadStageList])

  const onDragEnd = (result) => {
    const {source, destination} = result;
    if (!result.destination) {
        return;
    }
    const new_order = reorder(
        stages,
        source.index,
        destination.index
    );
    setStages(new_order)
    props.sortUserStage({stages: new_order}, (res) => {})
  }

  const renderEachItem = () => {
    let view = [];
    stages.forEach((item, index, dataList) => {
        const dragableFlag = !hasAccessFlag()
      view.push(
        <Draggable key={item.id} draggableId={`${item.id}`} index={index} isDragDisabled={dragableFlag}>
          {(provided, snapshot) => (
            <div
              className={"pipeline_stage_top_wrapper"}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <EachPipeline
                list={dataList}
                key={index}
                index={index}
                item={item}
                pipelineContainerRef={pipelineContainerRef}
                hasAccessFlag={hasAccessFlag()}
              />
            </div>
          )}
        </Draggable>
      )
    })
    return view;
  }

    const hasAccessFlag = () => {
    if(props.selectedPipeline !== null && props.selectedPipeline !== ""){
        if(Utils.getAccountData('userIsOwner')){
            return true;
        }
        else if(Utils.getAccountData('userIsAdmin')){
            if(props.selectedPipeline.user.role_user.role_id !== 1){
                return true;
            }
        }
        else if(Utils.getAccountData('userIsAgent')){
            if(props.selectedPipeline.user_id === Utils.getAccountData('userId')){
                return true;
            }
        }
    }
    return false;
    }

  return (
    <div className='pipeline__main__container'>
      {loading &&
        <div className="pipeline_stage_top_wrapper">
            <PipelineSkeleton/>
        </div>
      }

      {(!loading && stages.length === 0) &&
          <div className="noValid text-center alt">No stage created for this pipeline</div>
      }

      {(!loading && stages.length > 0) &&
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div id="cutomScrollbar1"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {renderEachItem()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      }

      {hasAccessFlag() && props.seletedPipelineId && !loading && stages.length === 0 && (
        <AddStageConfirmation onConfirm={() => setIsAddStageDrawer(true)} />
      )}

      {isAddStageDrawer && (
        <AddStageDrawer
          open={isAddStageDrawer}
          pipelineId={props.seletedPipelineId}
          onSuccessCallback={() => {
            props.reloadPipeline({ addStageFlag: true });
            props.reloadStageList(true);
          }}
          onClose={() => setIsAddStageDrawer(false)}
        />
      )}
    </div>
  );


}

const mapStateToProps = state => ({
  needToReloadPipeline: state.PipelineReducer.needToReloadPipeline,
  seletedPipelineId : state.PipelineReducer.seletedPipelineId,
  addPipeline : state.PipelineReducer.addPipeline,
  editPipeline : state.PipelineReducer.editPipeline, 
  needToReloadStageList: state.PipelineReducer.needToReloadStageList,
  addStage : state.PipelineReducer.addStage, 
  selectedPipeline : state.PipelineReducer.selectedPipeline, 
});
const mapDispatchToProps = dispatch => ({
  getUserStageByPipeline: (params, callback) => dispatch(getUserStageByPipeline(params, callback)),
  sortUserStage: (params, callback) => dispatch(sortUserStage(params, callback)),
  deleteAndMoveToStage: (params, callback) => dispatch(deleteAndMoveToStage(params, callback)),
  reloadStageList: (params, callback) => dispatch(reloadStageList(params, callback)),
  userStageShowInChart: (params, callback) => dispatch(userStageShowInChart(params, callback)),
  reloadPipeline: (params) => dispatch(reloadPipeline(params)),
})
const PipelineBody = connect(mapStateToProps, mapDispatchToProps)(PipelineBodyApp);
export default PipelineBody;