import { Drawer, styled } from "@material-ui/core";

export const AddStageDrawerStyles = styled(Drawer)(({ theme }) => ({
  "& .drawerWrapper": {
    width: 700,

    "& .drawerHeader": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 16,
      borderBottom: "1px solid #ddd",
    },
    "& .drawerHeaderActions": {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },

    "& .drawerBody": {
      padding: 16,

      "& .stageTitle input": {
        height: "39px",
      },

      "& .titleError": {
        color: "red",
        fontSize: 12,
        position: "absolute",
        bottom: -16,
      },

      "& .colorPicker input": {
        padding: "0 2px !important",
      },

      "& .inputDelete": {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },

      "& .addDInputStage": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",

        "& .MuiIconButton-root": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },

      "& .stageList": {
        maxHeight: "calc(100vh - 220px)",
        marginTop: 8,
        paddingBottom: 8,
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "#888 transparent", // Change color as needed
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Change color as needed
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Change color as needed
        },
      },
    },

    "& .tableWrapper": {
      "& .MuiTableHead-root": {
        color: "white",
        backgroundColor: "#fff",
        fontWeight: "bold",

        "& .MuiTableCell-head": {
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
    },

    "& .valueChip": {
      color: "#346fef",
      backgroundColor: "rgba(52, 111, 239, 0.05)",
      fontWeight: 600,
    },
    "& .closeButton": {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FF264A",
      cursor: "pointer",
    },

    "& .modalFooter": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "16px",
      borderTop: "1px solid rgba(148, 157, 178, 0.24)",
      gap: "16px",
      "& .submitButton": {
        "&:hover, &:focus": {
          backgroundColor: "#3f51b5",
        },
      },

      "& .cancelButton": {
        "&:hover, &:focus": {
          backgroundColor: "transparent !important",
        },
      },
    },
  },
}));