import React from "react";
import { Backdrop, Button, Divider, Fade, Modal } from "@material-ui/core";
import { addPipelineModalStyles } from "../../Deals/pipelineFilterWithPrivacy/pipelineFilterWithPrivacyStyle";

const AddStageConfirmation = ({ onConfirm }) => {
  const [open, setOpen] = React.useState(true);
  const classes = addPipelineModalStyles();

  const onClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <div>
              <h5 style={{ margin: 0, fontSize: 20 }}>No Stage Found</h5>
            </div>
          </div>
          <Divider />

          <div
            className={classes.modalBody}
            style={{ paddingBottom: 32, textAlign: "center" }}
          >
            <span>
              <p style={{ marginBottom: 8 }}>
                No stage are available for the selected pipeline.
              </p>
              <p style={{ margin: 0 }}>Would you like to add a new stage?</p>
            </span>
          </div>

          <div
            className={classes.modalFooter}
            style={{ backgroundColor: "white" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              size="small"
              disableElevation
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="submitButton"
              color="primary"
              size="small"
              disableElevation
              // startIcon={<AddIcon />}
              onClick={handleConfirm}
            >
              Create New Stage
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddStageConfirmation;
