import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import React, { useContext } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { pipelineItemStyles } from "./pipelineFilterWithPrivacyStyle";
import { HistoryProvider } from "../../../../App";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const PipelineItem = ({ item, selected, onChangePipeline, onClose }) => {
  const { id, title } = item || {};
  const classes = pipelineItemStyles();
  const history = useContext(HistoryProvider);

  const isSelected = selected && (
    <IconButton size="small" edge="end" className="checkAction">
      <CheckIcon style={{ fontSize: 16 }} />
    </IconButton>
  );

  const goToLink = (link) => {
    history.push({
      pathname: link,
    });
  };

  const handleEdit = () => {
    onChangePipeline(id, item);
    goToLink("/pipelines");
  };
  return (
    <ListItem
      button
      component={"div"}
      className={classes.root}
      selected={selected}
      onClick={() => {
        onChangePipeline(id, item);
        onClose();
      }}
    >
      <BootstrapTooltip
        arrow
        placement="top"
        title={title + " - " + item.user?.full_name}
      >
        <ListItemText
          primary={title}
          // secondary={"Secondary text"}
        />
      </BootstrapTooltip>
      <ListItemSecondaryAction className="secondaryAction">
        <IconButton
          size="small"
          edge="end"
          className="editAction"
          onClick={handleEdit}
        >
          <EditIcon style={{ fontSize: 16 }} />
        </IconButton>

        {isSelected}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PipelineItem;
