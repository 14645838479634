import React, { useContext, useState } from "react";
import { Button, Divider, List, Popover, Tab, Tabs } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BarChartIcon from "@material-ui/icons/BarChart";
import EditIcon from "@material-ui/icons/Edit";
import { PRIVACY_LEVEL } from "../../../../constants/CoreConstants";
import AddPipelineModal from "./AddPipelineModal";
import {
  PipelineFilterPopoverWrapper,
  pipelineFilterWithPrivacyStyles,
} from "./pipelineFilterWithPrivacyStyle";
import PipelineItem from "./PipelineItem";
import { HistoryProvider } from "../../../../App";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import CustomTabs from "./CustomTab";

const PipelineFilterWithPrivacy = ({
  pipeLineList,
  setPipeLineList,
  selectedPipeline,
  onChangePipeline,
}) => {
  const [dealPipelinePopover, setDealPipelinePopover] = useState(null);
  const [isAddModal, setIsAddModal] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const classes = pipelineFilterWithPrivacyStyles();
  const history = useContext(HistoryProvider);
  // const isUserOwnerOrAdmin = Utils.getAccountData("userIsOwner") || Utils.getAccountData("userIsAdmin");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDealPipelinePopover = (event) => {
    event.stopPropagation();
    setDealPipelinePopover(dealPipelinePopover ? null : event.currentTarget);
  };

  const openDealPipelinePopover = Boolean(dealPipelinePopover);
  const openDealPipelinePopoverId = openDealPipelinePopover
    ? "simple-action-popper"
    : undefined;

  const getSelectedPipeline = pipeLineList?.find(
    (item) => item.id === selectedPipeline
  )?.title;

  const filteredPipelineList = pipeLineList.filter((item) =>
    activeTab === 0
      ? item?.privacy_level === PRIVACY_LEVEL.GLOBAL
      : item?.privacy_level === PRIVACY_LEVEL.PRIVATE
  );

  const goToLink = (link) => {
    history.push({
      pathname: link,
    });
  };

  return (
    <div className={classes.root}>
      <div className="d-flex">
        <Button
          variant="outlined"
          className="pipelineButton"
          startIcon={<BarChartIcon />}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleDealPipelinePopover}
        >
          <BootstrapTooltip
            arrow
            placement="top"
            title={getSelectedPipeline || ""}
          >
            <div className="filterTitle">
              {getSelectedPipeline || "Select a Pipeline"}
            </div>
          </BootstrapTooltip>
        </Button>
        <Button
          variant="outlined"
          className="pipelineButton edit"
          onClick={() => goToLink("/pipelines")}
        >
          <EditIcon />
        </Button>
      </div>

      <Popover
        id={openDealPipelinePopoverId}
        open={openDealPipelinePopover}
        anchorEl={dealPipelinePopover}
        onClose={() => setDealPipelinePopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PipelineFilterPopoverWrapper>
          <CustomTabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            component={"div"}
          >
            <Tab label="Global Pipelines" className="tabItem" index={0} component={"div"} />
            <Tab label="My Pipelines" className="tabItem" index={1} component={"div"} />
          </CustomTabs>

          <div className="pipelineScroll" style={{ maxHeight: 285 }}>
            <List component={"div"} className={"pipelineList"} dense>
              {filteredPipelineList.map((item) => (
                <PipelineItem
                  key={item.id}
                  item={item}
                  selected={item.id === selectedPipeline}
                  onChangePipeline={onChangePipeline}
                  onClose={() => setDealPipelinePopover(null)}
                />
              ))}

              {filteredPipelineList.length === 0 && (
                <div className="noData">No pipeline found</div>
              )}
            </List>
          </div>

          <div className="footer">
            <Divider light />
            <Button
              variant="text"
              startIcon={<AddIcon />}
              color="primary"
              fullWidth
              className="addPipelineButton"
              onClick={() => setIsAddModal(true)}
            >
              New Pipeline
            </Button>
          </div>
        </PipelineFilterPopoverWrapper>
      </Popover>

      {isAddModal && (
        <AddPipelineModal
          open={isAddModal}
          onClose={() => {
            setDealPipelinePopover(null);
            setIsAddModal(false);
          }}
          setPipeLineList={setPipeLineList}
          activeTab={
            activeTab === 0 ? PRIVACY_LEVEL.GLOBAL : PRIVACY_LEVEL.PRIVATE
          }
          onChangePipeline={onChangePipeline}
        />
      )}
    </div>
  );
};

export default PipelineFilterWithPrivacy;
