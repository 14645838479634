export const MoreIcon = ({ height = 18, width = 18, fill = "#949DB2" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6C9.825 6 10.5 5.325 10.5 4.5C10.5 3.675 9.825 3 9 3C8.175 3 7.5 3.675 7.5 4.5C7.5 5.325 8.175 6 9 6ZM9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5ZM9 12C8.175 12 7.5 12.675 7.5 13.5C7.5 14.325 8.175 15 9 15C9.825 15 10.5 14.325 10.5 13.5C10.5 12.675 9.825 12 9 12Z"
        fill={fill}
      />
    </svg>
  );
};

export const searchIcon = ({ width = 20, height = 21 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -1 20 20"
      fill="#9ea0a8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0252 14.3469L18.5943 17.9152L17.4152 19.0943L13.8469 15.5252C12.5191 16.5895 10.8677 17.1684 9.16602 17.166C5.02602 17.166 1.66602 13.806 1.66602 9.66602C1.66602 5.52602 5.02602 2.16602 9.16602 2.16602C13.306 2.16602 16.666 5.52602 16.666 9.66602C16.6684 11.3677 16.0895 13.0191 15.0252 14.3469ZM13.3535 13.7285C14.4111 12.6409 15.0017 11.183 14.9993 9.66602C14.9993 6.44268 12.3885 3.83268 9.16602 3.83268C5.94268 3.83268 3.33268 6.44268 3.33268 9.66602C3.33268 12.8885 5.94268 15.4993 9.16602 15.4993C10.683 15.5017 12.1409 14.9111 13.2285 13.8535L13.3535 13.7285Z"
        fill={0.4}
        fillOpacity="#0D1126"
      />
    </svg>
  );
};
export const settingsIcon = ({ width = 21, height = 20 , fill="#546376", className = ""}) => {
  return (
    <svg
      className={`settings-icon ${className}`}
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.82559 12.4883C8.56568 13.1768 9.45715 13.5211 10.5 13.5211C11.5429 13.5211 12.4343 13.1768 13.1744 12.4883C13.9145 11.7997 14.2845 10.9703 14.2845 10C14.2845 9.02973 13.9145 8.20031 13.1744 7.51174C12.4343 6.82316 11.5429 6.47887 10.5 6.47887C9.45715 6.47887 8.56568 6.82316 7.82559 7.51174C7.0855 8.20031 6.71546 9.02973 6.71546 10C6.71546 10.9703 7.0855 11.7997 7.82559 12.4883ZM18.5232 10.9859L20.794 12.6291C21.0294 12.7856 21.0631 13.0047 20.8949 13.2864L18.7251 16.7606C18.5905 16.9797 18.3718 17.0423 18.0691 16.9484L15.3947 15.9624C14.6882 16.4319 14.0827 16.7606 13.5781 16.9484L13.1744 19.5775C13.1071 19.8592 12.9389 20 12.6698 20H8.3302C8.06107 20 7.89287 19.8592 7.82559 19.5775L7.42191 16.9484C6.78274 16.698 6.17721 16.3693 5.60533 15.9624L2.93092 16.9484C2.62815 17.0423 2.40949 16.9797 2.27493 16.7606L0.105126 13.2864C-0.0630757 13.0047 -0.0294353 12.7856 0.206047 12.6291L2.47677 10.9859C2.44313 10.7668 2.42631 10.4382 2.42631 10C2.42631 9.56182 2.44313 9.23318 2.47677 9.01408L0.206047 7.37089C-0.0294353 7.2144 -0.0630757 6.99531 0.105126 6.71361L2.27493 3.23944C2.40949 3.02034 2.62815 2.95775 2.93092 3.05164L5.60533 4.03756C6.31177 3.56808 6.9173 3.23944 7.42191 3.05164L7.82559 0.422535C7.89287 0.140845 8.06107 0 8.3302 0H12.6698C12.9389 0 13.1071 0.140845 13.1744 0.422535L13.5781 3.05164C14.2173 3.30203 14.8228 3.63067 15.3947 4.03756L18.0691 3.05164C18.3718 2.95775 18.5905 3.02034 18.7251 3.23944L20.8949 6.71361C21.0631 6.99531 21.0294 7.2144 20.794 7.37089L18.5232 9.01408C18.5569 9.23318 18.5737 9.56182 18.5737 10C18.5737 10.4382 18.5569 10.7668 18.5232 10.9859Z"
        fill={fill}
      ></path>
    </svg>
  );
};
